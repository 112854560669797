@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  font-family: 'Comfortaa', cursive;
}

main {
  min-height: 80vh;
}

.filter-label,
.filter label,
.filter-label .form-control,
.number-filter .form-control,
.date-filter .form-control {
  font-size: 12px !important;
}

